.page-contact {
  &__content {
    line-height: 1.75rem;
    margin-top: 3rem;
  }

  &__links {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1.5rem;
    margin-top: 5rem;

    &-item {
      background-color: var(--contact-link-bg);
      border: solid 1px var(--contact-link-border);
      border-radius: .75rem;
      box-sizing: border-box;
      color: var(--contact-link-color);
      display: block;
      line-height: 1.75rem;
      padding: 1.5rem 2rem;
      position: relative;
      text-align: center;
      text-decoration: none;
      transition: var(--transition);
      transition-property: color;

      &:hover {
        color: var(--contact-link-hover-color);
      }

      strong {
        letter-spacing: .075em;
      }

      //& + & {
      //  margin-top: 1rem;
      //}
    }

    &-icon {
      display: inline-block;
      height: 40px;
      left: 24px;
      margin-top: -20px;
      opacity: .07;
      position: absolute;
      top: 50%;
    }
  }

  &__image {
    margin-top: 8rem;
    text-align: center;

    img {
      width: 70%;
    }
  }

  @media (max-width: 768px) {
    &__links {
      &-item {
        padding: .75rem 1rem;
        text-align: left;
      }

      &-icon {
        height: 32px;
        left: initial;
        margin-top: 0;
        right: 16px;
        top: 16px;
      }
    }
  }

  @media (max-width: 480px) {
    &__links {
      grid-template-columns: 1fr;
      margin-top: 3rem;
    }

    &__image {
      margin-top: 5rem;

      img {
        width: 100%;
      }
    }
  }
}
