.page-about {
  &__cover {
    //filter: grayscale(100%);
    margin-top: 3rem;
    //transition: var(--transition);
    //transition-property: filter;
    width: 100%;

    &:hover {
      //filter: grayscale(60%);
    }
  }

  .c-bio {
    margin-top: 2rem;
  }

  .c-skills {
    margin-top: 5rem;
  }
}
