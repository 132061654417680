.c-page-header {
  &__tag {
    background-color: var(--page-header-tag-bg);
    color: var(--page-header-tag-color);
    display: inline-block;
    padding: .5rem 1rem;
    text-transform: uppercase;
  }

  &__title {
    color: var(--page-header-title-color);
    font-size: 2.5rem;
    font-weight: 700;
    line-height: 1.75em;
    margin: 0;
  }

  @media (max-width: 1200px) {
    &__tag {
      font-size: .8rem;
    }

    &__title {
      font-size: 2rem;
    }
  }

  @media (max-width: 768px) {
    &__title {
      font-size: 1.75rem;
    }
  }
}
