.c-navbar {
  align-items: center;
  background-color: var(--navbar-bg);
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  height: 4rem;
  padding: 0 1rem;

  &__logo {
    color: var(--navbar-logo-color);
    font-size: 1.75rem;
    font-weight: 900;
    letter-spacing: .075rem;
    text-decoration: none;
    text-transform: uppercase;
  }

  &__menu {
    cursor: pointer;
    padding: .5rem;

    img {
      height: 2.5rem;
      width: 2.5rem;
    }
  }
}
