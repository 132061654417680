@import "normalize-scss/fork-version/normalize";

:root {
  --color: #888888;
  --heading-color: #000000;
  --link-color: #888888;
  --font-size: 15px;
  --font-weight: 500;
  --transition: all .25s ease-in-out;
  --body-bg: #ffffff;
  --content-bg: #f8f8f8;
  --sidebar-bg: #ffffff;
  --sidebar-title-color: #000000;
  --sidebar-copyright-color: #b8b8b8;
  --sidebar-border: #e8e8e8;
  --sidebar-link: #888888;
  --sidebar-link-active: #000000;
  --intro-image-size: 12rem;
  --intro-name-color: #000000;
  --navbar-bg: #ffffff;
  --navbar-border: #e8e8e8;
  --navbar-logo-color: #000000;
  --page-header-tag-bg: #e8e8e8;
  --page-header-tag-color: #000000;
  --page-header-title-color: #000000;
  --bio-name-color: #000000;
  --bio-job-title-color: #888888;
  --bio-line-color: #e8e8e8;
  --bio-more-bg: #000000;
  --bio-more-color: #ffffff;
  --bio-more-bg-hover: #e8e8e8;
  --bio-more-color-hover: #888888;
  --skills-title-color: #000000;
  --skills-skill-color: #888888;
  --skills-skill-hover-color: #000000;
  --404-number-color: #888888;
  --404-text-color: #000000;
  --contact-link-color: #888888;
  --contact-link-border: #e8e8e8;
  --contact-link-bg: #ffffff;
  --contact-link-hover-color: #000000;
}

html, body {
  background-color: var(--body-bg);
  color: var(--color);
  font-family: Montserrat, sans-serif;
  font-size: var(--font-size);
  font-weight: var(--font-weight);
}

a {
  color: var(--link-color);
}
