.layout-main {
  display: flex;
  min-height: 100vh;
  min-height: 100dvh;
  position: relative;

  .c-sidebar {
    box-sizing: border-box;
    position: fixed;
  }

  .c-content {

  }

  .c-navbar {
    left: 0;
    position: fixed;
    right: 0;
    transition: var(--transition);
    transition-property: top;
    z-index: 999;
  }

  @media (min-width: 1201px) {
    .c-sidebar {
      border-right: solid 1px var(--sidebar-border);
      bottom: 0;
      left: 0;
      top: 0;
      width: 25rem;
    }

    .c-content {
      box-sizing: border-box;
      padding-left: 25rem;
      width: 100%;
    }

    .c-navbar {
      top: -4rem;
    }
  }

  @media (max-width: 1200px) {
    .c-sidebar {
      background-color: var(--body-bg);
      bottom: 0;
      left: -100rem;
      overflow-y: auto;
      padding-left: .5rem;
      padding-right: .5rem;
      right: 100%;
      top: 4rem;
      z-index: 999;

      &__content {
        margin: 0;
        text-align: center;
      }

      &__title {
        display: none;
      }

      &__nav {
        margin-top: 0;

        &-item {
          font-size: 1.25rem;
          font-weight: 500;
          //letter-spacing: .125rem;
          padding: 1rem 0;
          //text-transform: uppercase;

          &:hover, &:focus, &--active {
            font-weight: 700;
            //letter-spacing: .125rem;
          }
        }
      }

      &__copyright {
        display: none;
      }
    }

    &--with-sidebar .c-sidebar {
      left: 0;
      right: 0;
    }

    .c-content {
      padding-top: 4rem;
      width: 100%;
    }

    .c-navbar {
      background-color: var(--body-bg);
      border-bottom: solid 1px var(--navbar-border);
      top: 0;
    }
  }
}
