.page-privacy {
  &__content {
    line-height: 1.75em;
    margin-top: 3rem;

    h1, h2 {
      color: var(--heading-color);
    }

    h2 {
      font-size: 1.75rem;
    }
  }
  
  @media (max-width: 768px) {
    h2 {
      font-size: 1.25rem;
    }
  }
}
