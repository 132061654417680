.page-404 {
  box-sizing: border-box;
  display: flex;
  min-height: 100vh;
  padding: 2rem;

  &__inner {
    margin: auto;
    text-align: center;
  }

  &__number {
    color: var(--404-number-color);
    font-size: 5rem;
    font-weight: 700;
    letter-spacing: .075em;
  }

  &__text {
    color: var(--404-text-color);
    margin-top: .5rem;
  }
}
