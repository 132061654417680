.c-bio {
  &__name {
    color: var(--bio-name-color);
    font-size: 2.5rem;
    font-weight: 700;
  }

  &__title {
    color: var(--bio-job-title-color);
    font-size: 1.25rem;
    margin-top: .5rem;
  }

  &__introduction {
    border-top: solid 1px var(--bio-line-color);
    line-height: 1.75em;
    margin-top: 2rem;
    padding-top: 2rem;

    & > div, & > p {
      line-height: 1.75em;
      margin: 0;

      & + * {
        margin-top: 1.5rem;
      }
    }
  }

  &__more {
    background-color: var(--bio-more-bg);
    color: var(--bio-more-color);
    cursor: pointer;
    display: inline-block;
    margin-top: 2rem;
    padding: .75rem 1rem;
    transition: var(--transition);
    transition-property: background-color, color;

    &:hover {
      background-color: var(--bio-more-bg-hover);
      color: var(--bio-more-color-hover);
    }
  }

  @media (max-width: 1024px) {
    &__name {
      font-size: 2rem;
    }
  }
}
