.c-content-wrapper {
  &--boxed {
    box-sizing: border-box;
    margin: 0 auto;
    padding: 6rem 2rem;
    width: 100%;
  }

  &--large {
    max-width: 75rem;
  }

  &--medium {
    max-width: 60rem;
  }

  &--small {
    max-width: 50rem;
  }

  &--v-center {
    align-items: center;
    display: flex;
    height: 100%;
  }

  @media (max-width: 1200px) {
    &--boxed {
      padding-top: 4rem;
      padding-bottom: 4rem;
    }
  }

  @media (max-width: 1024px) {
    &--boxed {
      padding-top: 3rem;
      padding-bottom: 3rem;
    }
  }

  @media (max-width: 768px) {
    &--boxed {
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }
}
