.c-skills {
  &__name {
    color: var(--skills-title-color);
    font-size: 1.5rem;
    font-weight: 700;
    margin-bottom: 2rem;
  }

  &__list {
    display: grid;
    grid-column-gap: 2.5rem;
    grid-row-gap: 2rem;
  }

  &__skill {
    color: var(--skills-skill-color);
    transition: var(--transition);
    transition-property: color;

    &:hover {
      color: var(--skills-skill-hover-color);
    }
  }

  &__percent {
    border: solid 1px var(--skills-skill-color);
    box-sizing: border-box;
    margin-top: .5rem;
    transition: var(--transition);
    transition-property: border-color;

    &-inner {
      background-color: var(--skills-skill-color);
      height: 2px;
      transition: var(--transition);
      transition-property: background-color;
    }
  }

  &__skill:hover &__percent {
    border-color: var(--skills-skill-hover-color);

    &-inner {
      background-color: var(--skills-skill-hover-color);
    }
  }

  @media (min-width: 1025px) {
    &__list {
      grid-template-columns: repeat(4, 1fr);
    }
  }

  @media (max-width: 1024px) {
    &__list {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  @media (max-width: 768px) {
    &__list {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  @media (max-width: 480px) {
    &__list {
      //grid-template-columns: repeat(1, 1fr);
    }
  }
}
