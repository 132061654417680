.c-intro {
  align-items: center;
  display: flex;

  &__left {
    padding-right: 3rem;
  }

  &__image {
    img {
      border-radius: 100%;
      //filter: grayscale(100%);
      height: var(--intro-image-size);
      object-fit: cover;
      //transition: var(--transition);
      //transition-property: filter;
      width: var(--intro-image-size);

      &:hover {
        //filter: grayscale(60%);
      }
    }
  }

  &__right {
    box-sizing: border-box;
    flex: 1;
  }

  &__name {
    color: var(--intro-name-color);
    font-size: 2.5rem;
    font-weight: 700;
    letter-spacing: .25rem;
    text-transform: uppercase;
  }

  &__about {
    line-height: 1.75em;
    margin-top: 1.25rem;
  }

  &__links {
    margin-top: 1.25rem;

    &-item {
      display: inline-block;

      img {
        height: 1.75rem;
        width: 1.75rem;
      }

      & + & {
        margin-left: .75rem;
      }
    }
  }

  @media (max-width: 1024px) {
    display: block;
    text-align: center;

    &__left {
      padding-right: 0;
    }

    &__right {
      padding-top: 2rem;
    }

    &__name {
      font-size: 1.75rem;
    }
  }
}
