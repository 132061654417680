.c-sidebar {
  background-color: var(--sidebar-bg);
  display: flex;
  flex-direction: column;
  padding: 3rem 5rem;

  &__content {
    margin: auto 0;
  }

  &__title {
    color: var(--sidebar-title-color);
    letter-spacing: .25rem;
    font-size: 2rem;
    font-weight: 900;
    text-transform: uppercase;
  }

  &__nav {
    margin-top: 2.5rem;

    &-item {
      color: var(--sidebar-link);
      display: block;
      padding: .5rem 0;
      text-decoration: none;
      transition: var(--transition);
      transition-property: color, font-weight, letter-spacing;

      &:hover, &:focus, &--active {
        color: var(--sidebar-link-active);
        font-weight: 700;
        letter-spacing: .075em;
      }
    }
  }

  &__copyright {
    color: var(--sidebar-copyright-color);
    margin-top: 2.5rem;
  }
}
